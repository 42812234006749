// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-otazky-a-odpovede-strapi-faq-slug-tsx": () => import("./../../../src/pages/otazky-a-odpovede/{StrapiFaq.slug}.tsx" /* webpackChunkName: "component---src-pages-otazky-a-odpovede-strapi-faq-slug-tsx" */),
  "component---src-pages-ponuka-strapi-car-slug-tsx": () => import("./../../../src/pages/ponuka/{StrapiCar.slug}.tsx" /* webpackChunkName: "component---src-pages-ponuka-strapi-car-slug-tsx" */),
  "component---src-pages-ponuka-tsx": () => import("./../../../src/pages/ponuka.tsx" /* webpackChunkName: "component---src-pages-ponuka-tsx" */)
}

